
import { mapGetters } from 'vuex';

export default {
  name: 'Modal',
  computed: {
    ...mapGetters({
      isModalActive: 'ui/isModalActive',
      getModalContent: 'ui/getModalContent'
    })
  },

  watch: {
    isModalActive() {
      document.documentElement.classList[this.isModalActive ? 'add' : 'remove']('no-scroll');
    },

    $route() {
      this.closeModal();
    }
  },

  beforeMount() {
    document.addEventListener('keyup', this.closeModalEsc);
  },

  beforeDestroy() {
    document.removeEventListener('keyup', this.closeModalEsc);
  },

  methods: {
    closeModal() {
      this.$store.commit('ui/toggleModal', false);
    },

    closeModalEsc(e) {
      if (this.isModalActive && e.keyCode === 27) {
        this.$store.commit('ui/toggleModal', false);
      }
    }
  }
};
