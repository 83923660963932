
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
    }),
    locale() {
      return this.getLocalePrefix();
    }
  }
};
