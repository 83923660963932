
import { mapGetters } from 'vuex';
import SVGinfo from '../../assets/svg/info.svg';
import SVGclose from '../../assets/svg/close.svg';

export default {
  components: {
    SVGinfo,
    SVGclose
  },

  props: {
    data: {
      type: Object,
      default: () => { }
    }
  },

  computed: {
    ...mapGetters({
      restaurant: 'settings/restaurant',
      isModalActive: 'ui/isModalActive',
      modalPopup: 'settings/modalPopup'
    }),
    locale() {
      return this.getLocalePrefix();
    },
    localizedRefImage() {
      if (!this.modalPopup) { return null; }

      let refImage = null;

      switch (this.locale) {
        case '/en':
          refImage = this.modalPopup.refImageen?._ref || null;
          break;
        case '/fr':
          refImage = this.modalPopup.refImagefr?._ref || null;
          break;
        case '':
          refImage = this.modalPopup.refImagede?._ref || null;
          break;
        default:
          refImage = null;
      }

      // Ensure the content is not null
      return refImage || null;
    },
    hideBar() {
      // TODO: update this with the actual URLs once the project is done
      return this.$route.params.pathMatch === 'reservation';
    }
  },

  mounted() {
    this.checkFirstVisit();
  },

  methods: {
    toggleModal() {
      this.$store.commit('ui/toggleModal', !this.isModalActive);
    },

    checkFirstVisit() {
      const firstVisit = localStorage.getItem('firstVisit');

      // if not already set or date is more than 1 day ago
      const daysInMs = 24 * 60 * 60 * 1000; // 1 day in ms
      if (!firstVisit || new Date(firstVisit).getTime() < Date.now() - daysInMs) {
        setTimeout(() => {
          this.toggleModal();
        }, 3000);

        localStorage.setItem('firstVisit', new Date().toISOString());
      }
    }
  }
};
