
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      modalPopup: 'settings/modalPopup'
    }),
    locale() {
      return this.getLocalePrefix();
    },
    localizedRefImage() {
      if (!this.modalPopup) { return null; }

      switch (this.locale) {
        case '/en':
          return this.modalPopup.refImageen?._ref || null;
        case '/fr':
          return this.modalPopup.refImagefr?._ref || null;
        case '':
          return this.modalPopup.refImagede?._ref || null;
        default:
          return this.modalPopup.refImageen?._ref || null;
      }
    }
  }
};
